import React from 'react';

import Launcher from './components/Launcher';
import './app.scss';

const App = () => (
  <div className='app'>
    <Launcher />
  </div>
);

export default App;
