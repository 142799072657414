import React, { useState, useEffect } from 'react';

import classnames from 'classnames';
import * as moment from 'moment';
import { Container, Row, Col, Image, Form } from 'react-bootstrap';

import LoadingMask from './LoadingMask';
import TokenInput from './TokenInput';

import { buildQueryString } from '../services/QueryStringHelper';
import backend from '../services/RestUtilities';

import './launcher.scss';

moment.locale('gb');
const allScreenKey = '-1';

const envOptions = {
  LOCAL: 'https://localhost:5001',
  DEV: 'https://wmp-dti-dev-client.azurewebsites.net',
  UAT: 'https://wmp-dti-uat-client.azurewebsites.net',
  STAGING: 'https://wmp-dti-live-client-staging.azurewebsites.net',
  LIVE: 'https://wmp-dti-live-client.azurewebsites.net',
};

const sourceOptions = {
  NONE: '',
  WEB: 'web',
  IOS: 'app',
  ANDROID: 'app',
  VOICE: 'voice.alexa',
};

const deviceOptions = {
  NONE: '',
  WEB: '',
  IOS: 'ios',
  ANDROID: 'android',
  VOICE: '',
};

const getEnvNightRiderAttr = (env) => {
  switch (env) {
    case 'LOCAL':
      return { 'data-nr-qb-env-local': '' };
    case 'DEV':
      return { 'data-nr-qb-env-dev': '' };
    case 'UAT':
      return { 'data-nr-qb-env-uat': '' };
    case 'STAGING':
      return { 'data-nr-qb-env-staging': '' };
    case 'LIVE':
      return { 'data-nr-qb-env-live': '' };
    default:
      return '';
  }
};

const getSourceNightRiderAttr = (source) => {
  switch (source) {
    case 'WEB':
      return { 'data-nr-qb-source-web': '' };
    case 'IOS':
      return { 'data-nr-qb-source-ios': '' };
    case 'ANDROID':
      return { 'data-nr-qb-source-android': '' };
    case 'VOICE':
      return { 'data-nr-qb-source-voice': '' };
    default:
      return '';
  }
};

const renderLink = (url, text) => {
  return (
    <Row className='start-row-small'>
      <Col xs={{ span: 8, offset: 2 }} className='text-center'>
        <a
          className='start'
          href={url}
          data-nr-qb-submit=''
          rel='noopener noreferrer'
          target='_blank'
        >
          <p>{text}</p>
        </a>
      </Col>
    </Row>
  );
};

const isInternalLauncher =
  window.location.host &&
  (window.location.host.toLowerCase() === 'localhost:3000' ||
    window.location.host.toLowerCase() ===
      'wmp-dti-launcher.azurewebsites.net');

const Launcher = () => {
  const [circuits, setCircuits] = useState([]);
  const [circuit, setCircuit] = useState(null);
  const [cinemas, setCinemas] = useState([]);
  const [cinema, setCinema] = useState(null);
  const [movies, setMovies] = useState([]);
  const [movie, setMovie] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [session, setSession] = useState(null);
  const [screens, setScreens] = useState([]);
  const [screenId, setScreenId] = useState('');
  const [token, setToken] = useState('');
  const [version, setVersion] = useState('');
  const [loading, setLoading] = useState(false);
  const [env, setEnv] = useState('UAT');
  const [source, setSource] = useState('');
  const [loyalty, setLoyalty] = useState(false);
  const [bookingRef, setBookingRef] = useState('');
  const [externalOrderId, setExternalOrderId] = useState('');
  const [testId, setTestId] = useState('');
  const [testCaptureId, setTestCaptureId] = useState('');
  const [addCinemaId, setAddCinemaId] = useState(false);
  const [addCircuitId, setAddCircuitId] = useState(false);
  const [showBookingRef, setShowBookingRef] = useState(false);
  const [showOrderExternalId, setShowOrderExternalId] = useState(false);
  const [showTestId, setShowTestId] = useState(false);
  const [showTestCaptureId, setShowTestCaptureId] = useState(false);
  const [showStartTicketingActionButton, setShowStartTicketingActionButton] =
    useState(false);
  const [
    showStartConcessionsActionButton,
    setShowStartConcessionsActionButton,
  ] = useState(false);
  const [showStartGiftCardsActionButton, setShowStartGiftCardsActionButton] =
    useState(false);
  const [
    showStartPhysicalGiftCardsActionButton,
    setShowStartPhysicalGiftCardsActionButton,
  ] = useState(false);
  const [
    showStartGiftMembershipActionButton,
    setShowStartGiftMembershipActionButton,
  ] = useState(false);
  const [showStartVouchersActionButton, setShowStartVouchersActionButton] =
    useState(false);
  const [showStartSeasonPassActionButton, setShowStartSeasonPassActionButton] =
    useState(false);
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken') || ''
  );
  const [isTokenValid, setIsTokenValid] = useState(false);

  const hostname =
    circuit && env !== 'LOCAL' && env !== 'DEV' && env !== 'STAGING'
      ? circuit.startTicketingUrl
      : envOptions[env];

  const handleEnvChange = (env) => {
    setEnv(env);
    setCircuits([]);
    setCircuit(null);
    setCinemas([]);
    setCinema(null);
    setMovies([]);
    setMovie(null);
    setSessions([]);
    setSession(null);
    setScreens([]);
    setScreenId(allScreenKey);
    setAddCircuitId(env === 'LOCAL' || env === 'DEV' || env == 'STAGING');
  };

  const handleSourceChange = (source) => {
    setSource(source);
  };

  const handleCircuitChange = (e) => {
    const circuitId = parseInt(e.target.value, 10);
    const circuit = circuits.find((x) => x.id === circuitId);
    let cinema = null;
    const cinemas = circuit ? circuit.cinemas : [];
    if (cinemas.length > 0) {
      const cinemaId = cinemas[0].id;
      cinema = cinemas.find((x) => x.id === cinemaId);
    }
    setCircuit(circuit);
    setCinemas(cinemas);
    setCinema(cinema);
    setSessions([]);
    setScreens([]);
    setScreenId(allScreenKey);
  };

  const handleCinemaChange = async (e) => {
    const cinemaId = parseInt(e.target.value, 10);
    const cinema = cinemas.find((x) => x.id === cinemaId);
    setCinema(cinema);
  };

  const handleMovieChange = async (e) => {
    const movieId = parseInt(e.target.value, 10);
    const movie = movies.find((x) => x.filmId === movieId);
    setMovie(movie);
  };

  const handleSessionChange = (e) => {
    const sessionId = parseInt(e.target.value, 10);
    const session = sessions.find((x) => x.id === sessionId);
    setSession(session);
  };

  const handleScreenChange = (e) => {
    const screenId = e.target.value;
    let firstSession;
    if (screenId !== allScreenKey) {
      firstSession = sessions.find((x) => x.screen === screenId);
    } else {
      firstSession = sessions[0];
    }
    setScreenId(screenId);
    setSession(firstSession);
  };

  const renderEnvOptions = () => {
    if (isInternalLauncher) {
      return Object.keys(envOptions).map((option) => (
        <Form.Check
          key={option}
          name='groupOptions1'
          label={option}
          type='radio'
          id={option}
          onChange={() => handleEnvChange(option)}
          checked={option === env}
          {...getEnvNightRiderAttr(option)}
        />
      ));
    } else {
      return Object.keys(envOptions).map((option) => {
        if (option == 'UAT' || option == 'LIVE')
          return (
            <Form.Check
              key={option}
              name='groupOptions1'
              label={option}
              type='radio'
              id={option}
              onChange={() => handleEnvChange(option)}
              checked={option === env}
              {...getEnvNightRiderAttr(option)}
            />
          );
      });
    }
  };

  const renderCircuitOptions = () => {
    if (circuits.length === 0) return null;
    circuits.sort(function (a, b) {
      let nameA = a.name.toUpperCase();
      let nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    let circuitOptions = circuits.map((c) => (
      <option key={c.id} value={`${c.id}`} data-nr-qb-regionname=''>
        {c.name}
      </option>
    ));
    return circuitOptions;
  };

  const renderCinemaOptions = () => {
    if (cinemas.length === 0) return null;
    return cinemas.map((c) => (
      <option key={c.id} value={`${c.id}`} data-nr-qb-theatrename=''>
        {c.name}
      </option>
    ));
  };

  const renderMovieOptions = () => {
    if (movies.length === 0) return null;
    return movies.map((c) => (
      <option key={c.filmId} value={`${c.filmId}`} data-nr-qb-filmname=''>
        {c.title}
      </option>
    ));
  };

  const renderSessionOptions = () => {
    if (!sessions.length) return;
    let filteredSessions = sessions;

    if (screenId !== allScreenKey) {
      filteredSessions = sessions.filter((x) => {
        return x.screen === screenId;
      });
    }

    if (filteredSessions.length === 0) return null;

    return filteredSessions.map((x) => (
      <option key={x.id} value={`${x.id}`} data-nr-qb-sessionname=''>
        {moment(x.date).locale('en').format('Do MMM h:mm a')}{' '}
        {x.seatsSelection ? '(RS)' : ''}
      </option>
    ));
  };

  const renderScreenOptions = () => {
    if (!screens.length) return;
    let renderedScreens = screens.map((x) => (
      <option key={x} value={x} data-nr-qb-screenname=''>
        {x}
      </option>
    ));
    let allOption = (
      <option
        key={allScreenKey}
        value={`${allScreenKey}`}
        data-nr-qb-screenname=''
      >
        All
      </option>
    );
    renderedScreens.splice(0, 0, allOption);

    return renderedScreens;
  };

  const getQueryString = (hasPreviousParam) => {
    return buildQueryString(
      {
        source: sourceOptions[source],
        device: deviceOptions[source],
        cinemaId: addCinemaId && cinema && cinema.id,
        circuitId: addCircuitId && circuit && circuit.id,
        bookingRef: showBookingRef && bookingRef,
        requestData: loyalty && token,
        testId: showTestId && testId,
        testCaptureId: showTestCaptureId && testCaptureId,
      },
      hasPreviousParam
    );
  };

  const renderStartTicketingLink = () => {
    if (!session || !cinema || !showStartTicketingActionButton) return;
    const path = loyalty
      ? `${hostname}/api/startticketing/redirect/${cinema.externalId}/${session.externalId}`
      : `${hostname}/startticketing/${cinema.externalId}/${session.externalId}`;
    let url = `${path}${getQueryString()}`;
    return (
      <Row className='start-row'>
        <Col xs={{ span: 8, offset: 2 }} className='text-center'>
          <a
            className='start ticketing'
            href={url}
            data-nr-qb-submit=''
            rel='noopener noreferrer'
            target='_blank'
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
              <path fill='none' d='M0,0H24V24H0Z' />
              <path
                fill='currentColor'
                d='M22,10V6a2,2,0,0,0-2-2H4A2,2,0,0,0,2,6v4a2,2,0,0,1,0,4v4a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V14a2,2,0,0,1,0-4ZM20,8.54a4,4,0,0,0,0,6.92V18H4V15.46A4,4,0,0,0,6,12,4,4,0,0,0,4,8.54V6H20ZM10.55,8,9.61,9l3.05,3L9.61,15.06l.94.94,4-4Z'
              />
            </svg>
          </a>
        </Col>
      </Row>
    );
  };

  const renderConcessionsLandingPageLink = () => {
    if (!cinema || !circuit || !showStartConcessionsActionButton) return;
    const url = `${hostname}/concessions/landing/${getQueryString()}`;
    return renderLink(url, 'KIOSK LANDING PAGE');
  };

  const renderStartConcessionsLink = () => {
    if (!cinema || !circuit || !showStartConcessionsActionButton) return;
    const path = loyalty
      ? `${hostname}/api/startconcessions/redirect/${cinema.id}`
      : `${hostname}/startconcessions/${cinema.id}`;
    const url = `${path}${getQueryString()}`;
    return renderLink(url, 'START CONCESSIONS');
  };

  const renderStartEGiftCardsLink = () => {
    if (!circuit || !showStartGiftCardsActionButton) return;
    const path = `${hostname}/giftcards`;
    let url = `${path}${getQueryString()}`;
    return renderLink(url, 'START EGIFT CARDS');
  };

  const renderStartPhysicalGiftCardsLink = () => {
    if (!circuit || !showStartPhysicalGiftCardsActionButton) return;
    const path = `${hostname}/physicalgiftcards`;
    let url = `${path}${getQueryString()}`;
    return renderLink(url, 'START PHYSICAL GIFT CARDS');
  };

  const renderStartGiftMembershipLink = () => {
    if (!circuit || !showStartGiftMembershipActionButton) return;
    const path = `${hostname}/giftmembership`;
    let url = `${path}${getQueryString()}`;
    return renderLink(url, 'START GIFT MEMBERSHIP');
  };

  const renderStartLoyaltyUpgradeLink = () => {
    if (!circuit || !showStartGiftMembershipActionButton) return;
    const path = `${hostname}/loyalty/upgrade`;
    let url = `${path}${getQueryString()}`;
    return renderLink(url, 'START LOYALTY UPGRADE');
  };
  const renderStartSignUpLink = () => {
    const path = `${hostname}/signup`;
    let url = `${path}${getQueryString()}`;
    return renderLink(url, 'START TIERED SIGNUP');
  };

  const renderStartVouchersLink = () => {
    if (!circuit || !showStartVouchersActionButton) return;
    const path = `${hostname}/vouchers`;
    let url = `${path}${getQueryString()}`;
    return renderLink(url, 'START VOUCHERS');
  };

  const renderStartRefundsLink = () => {
    if (!circuit || !showOrderExternalId || !externalOrderId) {
      return;
    }
    const url = `${hostname}/refund/${externalOrderId}${getQueryString()}`;
    return renderLink(url, 'START REFUND');
  };

  const renderConfirmationLink = () => {
    if (!circuit || !showOrderExternalId || !externalOrderId) {
      return;
    }
    const url = `${hostname}/confirmation/${externalOrderId}${getQueryString()}`;
    return renderLink(url, 'CONFIRMATION PAGE');
  };

  const renderStartSeasonPassLink = () => {
    if (!cinema || !circuit || !showStartSeasonPassActionButton) return;
    const path = loyalty
      ? `${hostname}/api/startseasonpass/redirect?cinemaId=${cinema.externalId}`
      : `${hostname}/startseasonpass?cinemaId=${cinema.externalId}`;
    const url = `${path}${getQueryString(true)}`;
    return renderLink(url, 'START SEASON PASS');
  };

  useEffect(() => {
    if (!accessToken && isInternalLauncher) return;
    const fetchVersion = async () => {
      const url = `${envOptions[env]}/api/data/Version`;
      const response = await backend.get(url);
      if (response.ok) {
        const version = response.content;
        setVersion(version);
      }
    };
    const fetchCircuits = async () => {
      setLoading(true);
      const response = await backend.get(
        `${envOptions[env]}/api/data/Circuits`
      );
      if (response.ok) {
        const circuits = response.content;
        if (circuits.length > 0) {
          const circuit = circuits[0];
          const cinemas = circuit.cinemas;
          let cinema = null;
          if (cinemas.length > 0) {
            cinema = cinemas[0];
          }
          setCircuits(circuits);
          setCircuit(circuit);
          setCinemas(cinemas);
          setCinema(cinema);
          setShowStartTicketingActionButton(false);
          setShowStartConcessionsActionButton(false);
          setShowStartGiftCardsActionButton(true);
          setShowStartPhysicalGiftCardsActionButton(true);
          setShowStartGiftMembershipActionButton(true);
          setShowStartVouchersActionButton(true);
          setShowStartSeasonPassActionButton(true);
          setIsTokenValid(true);
        }
      } else if (response.errorContent === 'Invalid token') {
        window.alert('Invalid Launcher token');
        setIsTokenValid(false);
        setAccessToken('');
      } else {
        window.alert(
          'Could not talk to cms or api, there is a configuration problem...'
        );
        setIsTokenValid(false);
        setAccessToken('');
      }
      setLoading(false);
    };
    if (env) {
      fetchVersion();
      fetchCircuits();
    }
  }, [env, accessToken]);

  useEffect(() => {
    const fetchMovies = async () => {
      if (!circuit || !cinema) return;
      setLoading(true);
      let platformVersionToUse;
      if (env === 'LOCAL' || env === 'DEV') {
        let splitString = circuit.platformVersion.split('-');
        platformVersionToUse = `${splitString[0]}-${splitString[1]}-Dev`;
      } else {
        platformVersionToUse = circuit.platformVersion;
      }
      const response = await backend.get(
        `${envOptions[env]}/api/data/Movies/${cinema.id}?platformVersion=${platformVersionToUse}`
      );
      if (response.ok) {
        const movies = response.content;
        setMovies(movies);
        setMovie(movies.length ? movies[0] : null);
        setSessions([]);
        setShowStartTicketingActionButton(false);
        setShowStartConcessionsActionButton(true);
        setShowStartGiftCardsActionButton(true);
        setShowStartPhysicalGiftCardsActionButton(true);
        setShowStartGiftMembershipActionButton(true);
        setShowStartVouchersActionButton(true);
        setShowStartSeasonPassActionButton(true);
      }
      setLoading(false);
    };
    if (cinema) {
      fetchMovies();
    }
  }, [cinema, circuit, env, accessToken]);

  useEffect(() => {
    const fetchSessions = async () => {
      if (!movie || !circuit || !cinema) return;
      let platformVersionToUse;
      if (env === 'LOCAL' || env === 'DEV') {
        let splitString = circuit.platformVersion.split('-');
        platformVersionToUse = `${splitString[0]}-${splitString[1]}-Dev`;
      } else {
        platformVersionToUse = circuit.platformVersion;
      }
      const response = await backend.get(
        `${envOptions[env]}/api/data/Sessions/${cinema.id}/${movie.filmId}?platformVersion=${platformVersionToUse}`
      );
      if (response.ok) {
        let session = null;
        let screens = [];
        let sessions = response.content.filter(
          (x) => moment(x.date) > moment()
        );
        if (sessions.length > 0) {
          session = sessions[0];
          screens = [
            ...new Set(sessions.map((s) => s.screen).filter((s) => !!s)),
          ].sort((a, b) =>
            a.localeCompare(b, undefined, {
              numeric: true,
              sensitivity: 'base',
            })
          );
        } else {
          sessions = null;
        }

        const screenId = allScreenKey;

        setSessions(sessions);
        setSession(session);
        setScreens(screens);
        setScreenId(screenId);
        setShowStartTicketingActionButton(true);
        setShowStartConcessionsActionButton(true);
        setShowStartGiftCardsActionButton(true);
        setShowStartPhysicalGiftCardsActionButton(true);
        setShowStartGiftMembershipActionButton(true);
        setShowStartVouchersActionButton(true);
        setShowStartSeasonPassActionButton(true);
      }
    };
    if (movie) {
      fetchSessions();
    }
  }, [cinema, circuit, env, movie, accessToken]);

  return (
    <div className='launcher'>
      <Container>
        <Row className='logo-row'>
          <Col sm={3} className='text-center image-container'>
            <Image src='/images/boxoffice.png' />
          </Col>
          <Col sm={9}>
            <Row>
              <Col sm={3}>
                <Form.Label>Environment </Form.Label>
                <Form.Group controlId='Form.ControlsCheck'>
                  {renderEnvOptions()}
                </Form.Group>
                {isInternalLauncher && (
                  <TokenInput
                    accessToken={accessToken}
                    setAccessToken={setAccessToken}
                    setIsTokenValid={setIsTokenValid}
                  />
                )}
              </Col>
              <Col sm={3}>
                {isInternalLauncher && (
                  <>
                    <Form.Label>Salestracker Source</Form.Label>
                    <Form.Group controlId='Form.ControlsCheck'>
                      {Object.keys(sourceOptions).map((s) => (
                        <Form.Check
                          key={s}
                          name='groupOptions2'
                          label={s}
                          type='radio'
                          id={s}
                          onChange={() => handleSourceChange(s)}
                          checked={source === s}
                          {...getSourceNightRiderAttr(s)}
                        />
                      ))}
                    </Form.Group>
                  </>
                )}
              </Col>
              <Col sm={3}>
                {isInternalLauncher && (
                  <>
                    <Form.Label>Options</Form.Label>
                    <Form.Check
                      name='loyalty'
                      label='Loyalty Login'
                      type='checkbox'
                      checked={loyalty}
                      onChange={() => setLoyalty(!loyalty)}
                      data-nr-qb-loyaltylogin=''
                    />
                    <Form.Check
                      name='showBookingRef'
                      label='Booking Ref'
                      type='checkbox'
                      checked={showBookingRef}
                      onChange={() => setShowBookingRef(!showBookingRef)}
                      data-nr-qb-showbookingref=''
                    />
                    <Form.Check
                      label='OrderExternalId'
                      type='checkbox'
                      checked={showOrderExternalId}
                      onChange={() =>
                        setShowOrderExternalId(!showOrderExternalId)
                      }
                      data-nr-qb-showorderexternalid=''
                    />
                    <Form.Check
                      label='TestId'
                      type='checkbox'
                      checked={showTestId}
                      onChange={() => setShowTestId(!showTestId)}
                      data-nr-qb-showtestid=''
                    />
                    <Form.Check
                      label='TestCaptureId'
                      type='checkbox'
                      checked={showTestCaptureId}
                      onChange={() => setShowTestCaptureId(!showTestCaptureId)}
                      data-nr-qb-showtestcaptureid=''
                    />
                    <Form.Check
                      label='Add CinemaId'
                      type='checkbox'
                      checked={addCinemaId}
                      onChange={() => setAddCinemaId(!addCinemaId)}
                      data-nr-qb-addcinemaid=''
                    />
                    <Form.Check
                      label='Add CircuitId'
                      type='checkbox'
                      checked={addCircuitId}
                      onChange={() => setAddCircuitId(!addCircuitId)}
                      data-nr-qb-addcinemaid=''
                    />
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {isTokenValid && (
          <>
            <Row className='selectors-row mt-3'>
              <Col sm={6}>
                <Form.Group controlId='Form.ControlsSelect'>
                  <Form.Label>Circuit</Form.Label>
                  <Form.Control
                    as='select'
                    value={circuit ? circuit.id : ''}
                    onChange={handleCircuitChange}
                    placeholder='--Select--'
                    data-nr-qb-regionbtn=''
                  >
                    {renderCircuitOptions()}
                  </Form.Control>
                </Form.Group>
              </Col>
              {cinemas && (
                <Col sm={6}>
                  <Form.Group controlId='Form.ControlsSelect'>
                    <Form.Label>Cinema</Form.Label>
                    <Form.Control
                      as='select'
                      value={cinema?.id ? cinema.id : ''}
                      onChange={handleCinemaChange}
                      placeholder='Select a cinema'
                      data-nr-qb-theatrebtn=''
                    >
                      {renderCinemaOptions()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row className='my-3'>
              {movies.length ? (
                <Col sm={4}>
                  <Form.Group controlId='Form.ControlsSelect'>
                    <Form.Label>Movie</Form.Label>
                    <Form.Control
                      as='select'
                      value={movie ? movie.id : ''}
                      onChange={handleMovieChange}
                      data-nr-qb-filmbtn=''
                    >
                      {renderMovieOptions()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              ) : null}
              {screens && (
                <Col sm={4}>
                  <Form.Group controlId='Form.ControlsSelect'>
                    <Form.Label>Screen</Form.Label>
                    <Form.Control
                      as='select'
                      value={screenId}
                      onChange={handleScreenChange}
                      data-nr-qb-screenbtn=''
                    >
                      {renderScreenOptions()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              )}
              {sessions && (
                <Col sm={4}>
                  <Form.Group controlId='Form.ControlsSelect'>
                    <Form.Label>Session</Form.Label>
                    <Form.Control
                      as='select'
                      value={session ? session.id : ''}
                      onChange={handleSessionChange}
                      data-nr-qb-sessionbtn=''
                    >
                      {renderSessionOptions()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row className={classnames('loyalty-row', loyalty || 'hidden')}>
              <Col>
                <Form.Group controlId='Form.LoyaltyRequestData'>
                  <Form.Label>Request Data</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='paste encrypted requestData'
                    onChange={(e) => setToken(e.currentTarget.value)}
                    data-nr-qb-loyaltyrequestdata=''
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row
              className={classnames(
                'booking-ref-row',
                showBookingRef || 'hidden'
              )}
            >
              <Col>
                <Form.Group controlId='Form.BookingRef'>
                  <Form.Label>Booking reference</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='paste booking reference'
                    onChange={(e) => setBookingRef(e.currentTarget.value)}
                    data-nr-qb-bookingref=''
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row
              className={classnames(
                'order-external-id-ref-row',
                showOrderExternalId || 'hidden'
              )}
            >
              <Col>
                <Form.Group controlId='Form.OrderExternalId'>
                  <Form.Label>Order External Id</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='paste order external id (i.e. from the confirmation url)'
                    onChange={(e) => setExternalOrderId(e.currentTarget.value)}
                    data-nr-qb-orderexternalid=''
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row
              className={classnames(
                'order-external-id-ref-row',
                showTestId || 'hidden'
              )}
            >
              <Col>
                <Form.Group controlId='Form.TestId'>
                  <Form.Label>Test ID</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='enter a TestID like Vista.Everyman.ExistingTest'
                    onChange={(e) => setTestId(e.currentTarget.value)}
                    data-nr-qb-testid=''
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row
              className={classnames(
                'order-external-id-ref-row',
                showTestCaptureId || 'hidden'
              )}
            >
              <Col>
                <Form.Group controlId='Form.TestId'>
                  <Form.Label>Test Capture ID</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='enter a new TestCaptureID like Vista.Everyman.NewTest2'
                    onChange={(e) => setTestCaptureId(e.currentTarget.value)}
                    data-nr-qb-testcaptureid=''
                  />
                </Form.Group>
              </Col>
            </Row>

            {renderStartTicketingLink()}
            {renderConcessionsLandingPageLink()}
            {renderStartConcessionsLink()}
            {renderStartEGiftCardsLink()}
            {renderStartPhysicalGiftCardsLink()}
            {renderStartGiftMembershipLink()}
            {renderStartVouchersLink()}
            {renderStartRefundsLink()}
            {renderConfirmationLink()}
            {renderStartLoyaltyUpgradeLink()}
            {renderStartSignUpLink()}
            {renderStartSeasonPassLink()}
            {version && (
              <Row className='version-container'>
                <Col md={{ span: 4, offset: 4 }} className='text-center'>
                  <p>Version: {version}</p>
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
      {loading && <LoadingMask />}
    </div>
  );
};

export default Launcher;
